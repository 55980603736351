<template>
  <div>
    <div class="header" @click.stop="changes2" ref="viewBox">
      <div class="header-left">
        <img src="../assets/img/logo.png" />
      </div>
      <div class="header-right" @click.stop="changes">
        <img src="../assets/img/home-menu.png" />
      </div>
    </div>
    <div style="position: relative">
      <ul class="header-menu" ref="menu" v-if="menuShow">
        <li
          style="text-align: center"
          v-for="(menu, index) in menuData"
          :key="index"
          @click.stop="switchMenu(menu)"
        >
          <div v-if="!menu.child">
            <span v-html="menu.title"> </span>
          </div>
        </li>
      </ul>
    </div>

    <!-- <div class="disflex11" v-if="menuShow" @click.stop="closecopys1"></div> -->
  </div>
</template>

<script>
const menuData = [
  {
    title: "首&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;页",
    path: "/",
    isOpen: false,
  },
  {
    title: "企业服务",
    path: "/onlineTeaching",
  },
  {
    title: "行业资讯",
    path: "/study",
  },
  {
    title: "关于我们",
    path: "/about",
  },
];
export default {
  props: {
    // menuShow: Boolean,
  },
  // computed: {
  //   address() {
  //     return `${this.menuShow}`;
  //   },
  // },
  // watch: {
  //   address: function (newV) {
  //     console.log(newV);
  //     this.$emit("change", newV);
  //   },
  // },
  data() {
    return {
      menuData,
      copys1: -1,
      isS: false,
      menuShow: false,
    };
  },
  methods: {
    changes2() {
      if (this.menuShow == true) {
        this.menuShow = false;
      }
    },
    changes() {
      this.isS = true;
      if (this.menuShow == true) {
        this.menuShow = false;
        this.copys1 = -1;
      } else {
        this.copys1 = 1;
        this.menuShow = true;
      }
    },
    closecopys1() {
      this.menuShow = false;
      this.copys1 = -1;
    },
    switchMenu(menu) {
      menu.isOpen = !menu.isOpen;
      this.menuShow = false;
      if (!menu.child) {
        this.$router.push({ path: menu.path });
        this.$emit("change", false);
      }
    },
  },
  updated() {
    window.addEventListener("click", (e) => {
      const menu = this.$refs.menu;
      if (this.menuShow) {
        if (!menu.contains(e.target)) {
          this.$emit("change", false);
        }
      }
    });
  },
};
</script>

<style scoped>
.disflex11 {
  z-index: 99;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 999;
}
.header {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 65px;
  background-color: #1a1d26;
  opacity: 1;
  background: #013644;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
}
.header-left {
  flex: 1;
  text-align: left;
}
.header-left img {
  height: 33px;
  width: 121px;
}
.header-right img {
  height: 18px;
  width: 18px;
}
.header-menu {
  /* position: absolute; */
  border: 1px solid #013644;
  position: fixed;
  right: 0;
  top: 50px;
  background-color: #013644;
  color: #fff;
  z-index: 100;
  width: 100%;
  /* height: 428px; */
  opacity: 0.92;
  z-index: 9999;
}
.header-menu > li {
  padding: 15px 0;
}
.header-menu-child {
  padding-top: 15px;
  transition: all 0.2s ease;
}
.header-menu-arrow {
  width: 15px;
  height: 15px;
  position: relative;
  top: 2px;
  margin-left: 10px;
  transition: all 0.2s ease;
}
.header-menu-child > li {
  color: #76787e;
  padding: 5px 0;
  text-align: left;
  margin-left: 10px;
  margin-bottom: 10px;
  position: relative;
  left: 40px;
}
.header-menu-child > li:last-child {
  margin-bottom: 0;
}
</style>
